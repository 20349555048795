import * as React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './company-description.module.scss';

export const CompanyDescription = () => {
  const intl = useIntl();

  return (
    <div
      className={`${styles.companyDescription}`}
      dangerouslySetInnerHTML={{
        __html: intl.formatMessage({ id: 'about_us.description' }),
      }}
    />
  );
};
