import * as React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './eu-program.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { useSiteMetadata } from '../../../../hooks/useSiteMetadata';

export const EuProgram = () => {
  const intl = useIntl();
  const { siteMainLang } = useSiteMetadata();

  if (intl.locale !== siteMainLang) {
    return null;
  }
  return (
    <div className={styles.euProgram}>
      <div className="d-flex justify-content-between">
        <StaticImage
          src="../../../../images/about-us/EU.png"
          placeholder="tracedSVG"
          loading="lazy"
          width={300}
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt={intl.formatMessage({ id: 'about_us.eu_program.image.eu_flag_alt' })}
        />
        <StaticImage
          src="../../../../images/about-us/Innovation-program.png"
          placeholder="tracedSVG"
          loading="lazy"
          width={300}
          quality={95}
          formats={['auto', 'webp', 'avif']}
          alt={intl.formatMessage({ id: 'about_us.eu_program.image.innovation_program_alt' })}
        />
      </div>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'about_us.eu_program.text',
          }),
        }}
      />
    </div>
  );
};
