import * as React from 'react';
import { PageProps } from 'gatsby';
import loadable from '@loadable/component';
import { MainTemplate } from '../templates/main/main';
import { SEO } from '../components/seo/seo';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import { PageHeadline } from '../components/page-headline/page-headline';
import { CompanyDescription } from '../ui/pages/about-us/company-description/company-description';
import { EuProgram } from '../ui/pages/about-us/eu-program/eu-program';
const CompanyVideo = loadable(
  () => import('../ui/pages/about-us/company-video/company-video')
);

const AboutUsPage = ({ location }: PageProps) => {
  const intl = useIntl();

  return (
    <MainTemplate>
      <SEO
        location={location}
        title={intl.formatMessage({ id: 'about_us.seo_title' })}
        description={intl.formatMessage({ id: 'about_us.seo_description' })}
      />
      <div className="container">
        <PageHeadline>
          <FormattedMessage id="about_us.headline" />
        </PageHeadline>
        <CompanyDescription />
        <CompanyVideo />
        <EuProgram />
      </div>
    </MainTemplate>
  );
};

export default AboutUsPage;
